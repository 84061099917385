import React, {FC, useState, useEffect, useRef, useMemo, useCallback} from 'react';
import {InstantSearch, Configure} from 'react-instantsearch-dom';
import * as R from 'ramda';
import {styled} from '@mui/material/styles';
import Dialog, {DialogProps} from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import CloseIcon from '@mui/icons-material/Close';
import config from '../../../app/Config';
import Consts from '../../../app/Consts';
import searchClient from '../../../utils/algoliaSearchClient';
import {QueryCriteria, Facet} from '../../../types';
import CustomSelect from '../../Select/CustomSelect';
import {Button} from '../../Button';
import SelectorCloseConfirmation from '../../Modal/SelectorCloseConfirmation';
import CustomCurrentRefinements from './CustomCurrentRefinements';
import CustomStats from './CustomStats';
import CriteriaSection from './CriteriaSection';
import ExcludeRefinementList from './ExcludeRefinementList';
import IncludeRefinementList from './IncludeRefinementList';
import BulkSKUSelector from './BulkSKUSelector';
import NoResultErrorStats from './NoResultErrorStats';

const PREFIX = 'ProductSelector';

const classes = {
  dialogPaper: `${PREFIX}-dialogPaper`,
  hidden: `${PREFIX}-hidden`,
  dialogAction: `${PREFIX}-dialogAction`,
  dialogContent: `${PREFIX}-dialogContent`,
};

const StyledDialog = styled(Dialog)(() => ({
  [`& .${classes.dialogPaper}`]: {
    left: '25%',
    width: '50%',
  },

  [`& .${classes.hidden}`]: {
    display: 'none',
  },

  [`& .${classes.dialogAction}`]: {
    padding: '1rem 1.5rem',
  },

  [`& .${classes.dialogContent}`]: {
    padding: '1rem 2.625rem',
    display: 'flex',
    flexDirection: 'column',
    gridGap: '1rem',
  },
}));

type TooltipProps = {
  disabled?: boolean;
  disabledText?: string;
  children: React.ReactNode;
};

const OptionalTooltip: FC<TooltipProps> = ({disabled, children}) => (
  <>
    {disabled ? (
      <Tooltip
        title="add inclusion and/or exclusion criteria to continue"
        sx={{cursor: 'not-allowed'}}
        placement="top"
      >
        <span>{children}</span>
      </Tooltip>
    ) : (
      children
    )}
  </>
);

const brandFacet = Consts.AlgoliaFacet.ProductBrand.Index;
const brandFacetLabel = Consts.AlgoliaFacet.ProductBrand.Label;
const departmentFacet = Consts.AlgoliaFacet.ProductDepartment.Index;
const departmentFacetlabel = Consts.AlgoliaFacet.ProductDepartment.Label;
const productGroupFacet = Consts.AlgoliaFacet.ProductGroup.Index;
const productGroupFacetlabel = Consts.AlgoliaFacet.ProductGroup.Label;
const supplierFacet = Consts.AlgoliaFacet.ProductSupplier.Index;
const supplierFacetLabel = Consts.AlgoliaFacet.ProductSupplier.Label;
const seasonCodeFacet = Consts.AlgoliaFacet.ProductSeasonCode.Index;
const seasonCodeFacetLabel = Consts.AlgoliaFacet.ProductSeasonCode.Label;
const productSkuFacet = Consts.AlgoliaFacet.ProductSku.Index;
const productSkuFacetLabel = Consts.AlgoliaFacet.ProductSku.Label;
const productSkuBulkFacet = Consts.AlgoliaFacet.ProductSkuBulk.Label;
const productSkuBulkFacetLabel = Consts.AlgoliaFacet.ProductSkuBulk.Label;

const CriteriaSectionHeaderTitle = styled('div')`
  font-weight: 500;
  font-size: 1.5rem;
`;

const CriteriaSelectorContainer = styled('div')`
  display: flex;
`;
const CriteriaSelect = styled(CustomSelect)`
  flex: 0 0 12.5rem;
  margin-right: 1rem;
`;
const CriteriaRefinementListContainer = styled('div')`
  flex: 1;
`;

const StyledDialogTitle = styled('div')`
  font-size: 2rem;
  font-weight: 500;
  display: flex;
  padding: 2.875rem;
  justify-content: center;
  align-items: center;
  > span {
    flex-grow: 1;
  }
`;
const ApplyCriteriaButton = styled(Button)`
  width: 22rem;
`;

const inclusionFacetLimit = 50;
const inclusionFacets = [
  {
    label: departmentFacetlabel,
    value: departmentFacet,
    pluralLabel: Consts.AlgoliaFacet.ProductDepartment.PluralLabel,
  },
  {
    label: supplierFacetLabel,
    value: supplierFacet,
    pluralLabel: Consts.AlgoliaFacet.ProductSupplier.PluralLabel,
  },
  {
    label: brandFacetLabel,
    value: brandFacet,
    pluralLabel: Consts.AlgoliaFacet.ProductBrand.PluralLabel,
  },
  {
    label: seasonCodeFacetLabel,
    value: seasonCodeFacet,
    pluralLabel: Consts.AlgoliaFacet.ProductSeasonCode.PluralLabel,
  },
  {
    label: productGroupFacetlabel,
    value: productGroupFacet,
    pluralLabel: Consts.AlgoliaFacet.ProductGroup.PluralLabel,
  },
  {
    label: productSkuFacetLabel,
    value: productSkuFacet,
    pluralLabel: Consts.AlgoliaFacet.ProductSku.PluralLabel,
  },
  {
    label: productSkuBulkFacetLabel,
    value: productSkuBulkFacet,
    pluralLabel: Consts.AlgoliaFacet.ProductSkuBulk.PluralLabel,
  },
];
const exclusionFacets = [
  {
    label: departmentFacetlabel,
    value: departmentFacet,
    pluralLabel: Consts.AlgoliaFacet.ProductDepartment.PluralLabel,
  },
  {
    label: supplierFacetLabel,
    value: supplierFacet,
    pluralLabel: Consts.AlgoliaFacet.ProductSupplier.PluralLabel,
  },
  {
    label: brandFacetLabel,
    value: brandFacet,
    pluralLabel: Consts.AlgoliaFacet.ProductBrand.PluralLabel,
  },
  {
    label: seasonCodeFacetLabel,
    value: seasonCodeFacet,
    pluralLabel: Consts.AlgoliaFacet.ProductSeasonCode.PluralLabel,
  },
  {
    label: productGroupFacetlabel,
    value: productGroupFacet,
    pluralLabel: Consts.AlgoliaFacet.ProductGroup.PluralLabel,
  },
  {
    label: productSkuFacetLabel,
    value: productSkuFacet,
    pluralLabel: Consts.AlgoliaFacet.ProductSku.PluralLabel,
  },
  {
    label: productSkuBulkFacetLabel,
    value: productSkuBulkFacet,
    pluralLabel: Consts.AlgoliaFacet.ProductSkuBulk.PluralLabel,
  },
];

const defaultCriteria = {
  indexName: Consts.AlgoliaIndex.Products,
  filters: `entityCode:${config.entityCode}`,
  facetInclusions: [
    {
      name: departmentFacet,
      facetType: Consts.AlgoliaFacet.ProductDepartment.Type,
      values: [],
    },
    {
      name: supplierFacet,
      facetType: Consts.AlgoliaFacet.ProductSupplier.Type,
      values: [],
    },
    {
      name: brandFacet,
      facetType: Consts.AlgoliaFacet.ProductBrand.Type,
      values: [],
    },
    {
      name: seasonCodeFacet,
      facetType: Consts.AlgoliaFacet.ProductSeasonCode.Type,
      values: [],
    },
    {
      name: productGroupFacet,
      facetType: Consts.AlgoliaFacet.ProductGroup.Type,
      values: [],
    },
    {
      name: productSkuFacet,
      facetType: Consts.AlgoliaFacet.ProductSku.Type,
      values: [],
    },
  ],
  facetExclusions: [
    {
      name: departmentFacet,
      facetType: Consts.AlgoliaFacet.ProductDepartment.Type,
      values: [],
    },
    {
      name: supplierFacet,
      facetType: Consts.AlgoliaFacet.ProductSupplier.Type,
      values: [],
    },
    {
      name: brandFacet,
      facetType: Consts.AlgoliaFacet.ProductBrand.Type,
      values: [],
    },
    {
      name: seasonCodeFacet,
      facetType: Consts.AlgoliaFacet.ProductSeasonCode.Type,
      values: [],
    },
    {
      name: productGroupFacet,
      facetType: Consts.AlgoliaFacet.ProductGroup.Type,
      values: [],
    },
    {
      name: productSkuFacet,
      facetType: Consts.AlgoliaFacet.ProductSku.Type,
      values: [],
    },
  ],
};

type RefineMentListItem = {
  label: string;
  value: string[];
  count: number;
  isRefined: boolean;
};

type AlgoliaFacet = {
  Index: string;
  Type: string;
  Label: string;
  PluralLabel: string;
  Placeholder: string;
  Composited?: boolean;
};

type Props = {
  open: boolean;
  productCriteria?: any;
  defaultBuyerDepartments?: any[];
  defaultSuppliers?: any[];
  handleClose: () => void;
  onApplyCriteria?: (productCriteria: QueryCriteria | null) => void;
  scrollType?: 'paper' | 'body';
  readOnly?: boolean;
} & DialogProps;

type FacetUpdate = {
  name: string;
  facetType: string | undefined;
  values: string[];
};

const ProductSelector: FC<Props> = ({
  open,
  productCriteria,
  defaultBuyerDepartments,
  defaultSuppliers,
  handleClose,
  onApplyCriteria,
  scrollType = 'paper',
  readOnly = false,
  ...dialogProps
}) => {
  const noResultRef = useRef<HTMLDivElement | null>(null);
  const initialCriteriaSet = useRef(false);
  const [visibleInclusionFacet, setVisibleInclusionFacet] = useState(inclusionFacets[0]);
  const [visibleExclusionFacet, setVisibleExclusionFacet] = useState(exclusionFacets[0]);
  const [criteria, setCriteria] = useState<QueryCriteria>(productCriteria || defaultCriteria);
  const [closeConfirmModalOpen, setCloseConfirmModalOpen] = useState(false);
  const [resultCount, setResultCount] = useState(0);

  const noFacetsSelected = useMemo(
    () =>
      criteria?.facetInclusions.every((x) => x.values?.length === 0) &&
      criteria?.facetExclusions.every((x) => x.values?.length === 0),
    [criteria]
  );

  useEffect(() => {
    if (initialCriteriaSet.current) return;
    initialCriteriaSet.current = true;
    if (!productCriteria) {
      const criteria: QueryCriteria = {...defaultCriteria};
      const supplierFacetInclusion = criteria.facetInclusions.find((x) => x.name === supplierFacet);
      const departmentFacetInclusion = criteria.facetInclusions.find(
        (x: Facet) => x?.name === departmentFacet
      );
      const inclusionSuppliers = (defaultSuppliers ?? []).map((x) => `${x.number}--${x.name}`);
      const inclusionDepartments = (defaultBuyerDepartments ?? []).map(
        (x) => `${x.number}--${x.description}`
      );
      const newCriteria = {
        ...criteria,
        facetInclusions: [
          {...supplierFacetInclusion, values: inclusionSuppliers},
          {...departmentFacetInclusion, values: inclusionDepartments},
        ],
      };
      setCriteria(newCriteria);
    } else {
      setCriteria(productCriteria);
    }
  }, [productCriteria, defaultSuppliers, defaultBuyerDepartments]);

  const updateFacetArray = (prevFacets: Facet[], updateFacets?: Facet[]): Facet[] => {
    const updatedFacetsMap = new Map<string, Facet>();

    prevFacets.forEach((facet) => {
      if (facet.name) {
        updatedFacetsMap.set(facet.name, facet);
      }
    });

    updateFacets?.forEach((updateFacet) => {
      const existingFacet = updateFacet.name && updatedFacetsMap.get(updateFacet.name);
      if (existingFacet) {
        updatedFacetsMap.set(updateFacet.name as string, {
          ...existingFacet,
          values: Array.from(
            new Set([...(existingFacet.values ?? []), ...(updateFacet.values ?? [])])
          ),
        });
      } else {
        updatedFacetsMap.set(updateFacet.name as string, updateFacet);
      }
    });

    return Array.from(updatedFacetsMap.values());
  };

  const updateCriteria = useCallback(
    (updates: Partial<QueryCriteria>) => {
      setCriteria((prev) => ({
        ...prev,
        ...updates,
        ...(updates.facetInclusions && {
          facetInclusions: updateFacetArray(prev.facetInclusions, updates.facetInclusions),
        }),
        ...(updates.facetExclusions && {
          facetExclusions: updateFacetArray(prev.facetExclusions, updates.facetExclusions),
        }),
        resultCount: noFacetsSelected ? 0 : updates.resultCount ?? prev.resultCount,
      }));
    },
    [setCriteria, noFacetsSelected]
  );

  const updateFacets = (
    attribute: string,
    values: string[],
    facetType: string | undefined,
    isInclusion: boolean
  ) => {
    const update: FacetUpdate = {name: attribute, facetType, values};
    updateCriteria({
      [isInclusion ? 'facetInclusions' : 'facetExclusions']: [update],
    });
  };

  const handleRefine = (item: RefineMentListItem, attribute: AlgoliaFacet['Index']) => {
    const facet = Object.values(Consts.AlgoliaFacet).find((f) => f.Index === attribute);
    if (!facet) return;

    const exclusionValues = item.value.filter((val) => val.startsWith('-'));
    const inclusionValues = item.value.filter((val) => !val.startsWith('-'));

    updateFacets(attribute, inclusionValues, facet.Type, true);
    updateFacets(attribute, exclusionValues, facet.Type, false);
  };

  const applyCriteria = () => {
    if (resultCount === 0) {
      if (noResultRef.current) {
        noResultRef.current.scrollIntoView({behavior: 'smooth'});
      }
    } else if (onApplyCriteria) {
      onApplyCriteria({...criteria, resultCount} as QueryCriteria);
    }
  };

  const onDeleteFacetValue = (
    attribute: AlgoliaFacet['Index'],
    valueToDelete: string,
    isInclusion: boolean
  ) => {
    setCriteria((prevCriteria) => {
      const facetArray = isInclusion ? prevCriteria.facetInclusions : prevCriteria.facetExclusions;
      const facetsMap = new Map(facetArray.map((f) => [f.name, f]));

      const facet = facetsMap.get(attribute);
      if (!facet) return prevCriteria;

      const updatedFacet = {
        ...facet,
        values: facet.values?.filter((x) => x !== valueToDelete) ?? [],
      };
      facetsMap.set(attribute, updatedFacet);
      return {
        ...prevCriteria,
        [isInclusion ? 'facetInclusions' : 'facetExclusions']: Array.from(facetsMap.values()),
      };
    });
  };

  const onResult = (count: number) => {
    setResultCount(count);
  };

  const getListItem = (facet: AlgoliaFacet, inclusive?: boolean) => {
    const defaultRefinement = handleDefaultRefinement(facet, inclusive);
    if (inclusive) {
      return (
        <div
          className={
            visibleInclusionFacet && visibleInclusionFacet.label === facet.Label
              ? ''
              : classes.hidden
          }
        >
          <IncludeRefinementList
            attribute={facet.Index}
            defaultRefinement={defaultRefinement}
            limit={inclusionFacetLimit}
            onRefine={handleRefine}
            placeholder={facet.Placeholder}
            renderLabel={(label: string) => renderLabel(facet, label)}
            searchable
          />
        </div>
      );
    } else {
      return (
        <div
          className={
            visibleExclusionFacet && visibleExclusionFacet.label === facet.Label
              ? ''
              : classes.hidden
          }
        >
          <ExcludeRefinementList
            attribute={facet.Index}
            defaultRefinement={defaultRefinement}
            limit={inclusionFacetLimit * 2}
            onRefine={handleRefine}
            placeholder={facet.Placeholder}
            renderLabel={(label: string) => renderLabel(facet, label)}
            searchable
          />
        </div>
      );
    }
  };

  const getBulkSKUListItem = (inclusive?: boolean) => {
    if (inclusive) {
      return (
        <div
          className={
            visibleInclusionFacet &&
            visibleInclusionFacet.label === Consts.AlgoliaFacet.ProductSkuBulk.Label
              ? ''
              : classes.hidden
          }
        >
          <BulkSKUSelector
            attribute={Consts.AlgoliaFacet.ProductSkuBulk.Index}
            onSearchResolve={updateCriteria}
            inclusive
          />
        </div>
      );
    } else {
      return (
        <div
          className={
            visibleExclusionFacet &&
            visibleExclusionFacet.label === Consts.AlgoliaFacet.ProductSkuBulk.Label
              ? ''
              : classes.hidden
          }
        >
          <BulkSKUSelector
            attribute={Consts.AlgoliaFacet.ProductSkuBulk.Index}
            onSearchResolve={updateCriteria}
          />
        </div>
      );
    }
  };

  const getFacetLabelByFacetName = (label: string, attribute: AlgoliaFacet['Index']) => {
    const facet = Object.values(Consts.AlgoliaFacet).find(
      (facet: AlgoliaFacet) => facet.Composited && facet.Index === attribute
    );

    if (facet) {
      return renderLabel(facet, label);
    }
    return label;
  };

  const renderLabel = (facet: AlgoliaFacet, label: string) => {
    if (facet.Index === productSkuFacet) {
      return label.replace('--', ' ');
    } else if (facet.Composited) {
      return label.split('--')[1];
    }
    return label;
  };

  const handleDefaultRefinement = (facet: AlgoliaFacet, inclusive?: boolean) => {
    if (inclusive) {
      return [...(criteria.facetInclusions.find((x) => x.name === facet.Index)?.values ?? [])];
    } else {
      return [...(criteria.facetExclusions.find((x) => x.name === facet.Index)?.values ?? [])];
    }
  };

  const getInclusionCriteria = () => (
    <CriteriaSection title="Add Inclusion Criteria" readOnly={readOnly}>
      <CriteriaSelectorContainer>
        <CriteriaSelect
          options={inclusionFacets}
          defaultValue={inclusionFacets[0]}
          onChanged={setVisibleInclusionFacet}
        />
        <CriteriaRefinementListContainer>
          {getListItem(Consts.AlgoliaFacet.ProductDepartment, true)}
          {getListItem(Consts.AlgoliaFacet.ProductSupplier, true)}
          {getListItem(Consts.AlgoliaFacet.ProductBrand, true)}
          {getListItem(Consts.AlgoliaFacet.ProductSeasonCode, true)}
          {getListItem(Consts.AlgoliaFacet.ProductGroup, true)}
          {getListItem(Consts.AlgoliaFacet.ProductSku, true)}
          {getBulkSKUListItem(true)}
        </CriteriaRefinementListContainer>
      </CriteriaSelectorContainer>
    </CriteriaSection>
  );

  const getExcludeCriteria = () => (
    <CriteriaSection title="Add Exclusion Criteria" readOnly={readOnly}>
      <CriteriaSelectorContainer>
        <CriteriaSelect
          options={exclusionFacets}
          defaultValue={exclusionFacets[0]}
          onChanged={setVisibleExclusionFacet}
        />
        <CriteriaRefinementListContainer>
          {getListItem(Consts.AlgoliaFacet.ProductDepartment)}
          {getListItem(Consts.AlgoliaFacet.ProductSupplier)}
          {getListItem(Consts.AlgoliaFacet.ProductBrand)}
          {getListItem(Consts.AlgoliaFacet.ProductSeasonCode)}
          {getListItem(Consts.AlgoliaFacet.ProductGroup)}
          {getListItem(Consts.AlgoliaFacet.ProductSku)}
          {getBulkSKUListItem()}
        </CriteriaRefinementListContainer>
      </CriteriaSelectorContainer>
    </CriteriaSection>
  );

  const closeSelector = () => {
    if (readOnly) {
      handleClose();
      return;
    }
    const hasIncusions = R.any((facet) => !R.isEmpty(facet.values), criteria.facetInclusions || []);
    const hasExclusions = R.any(
      (facet) => !R.isEmpty(facet.values),
      criteria.facetExclusions || []
    );

    if (
      R.equals(productCriteria, criteria) ||
      (!productCriteria && !hasIncusions && !hasExclusions)
    ) {
      handleClose();
    } else {
      setCloseConfirmModalOpen(true);
    }
  };

  const onClearCriteria = () => {
    setCriteria((prev) => ({
      ...prev,
      facetInclusions: prev.facetInclusions.map((facet) => ({
        ...facet,
        values: [],
      })),
      facetExclusions: prev.facetExclusions.map((facet) => ({
        ...facet,
        values: [],
      })),
    }));
  };

  return (
    <StyledDialog
      fullScreen
      open={open}
      onClose={closeSelector}
      scroll={scrollType}
      classes={{paper: classes.dialogPaper}}
      {...dialogProps}
    >
      <InstantSearch searchClient={searchClient} indexName={Consts.AlgoliaIndex.Products}>
        <StyledDialogTitle>
          <span>Product Selector</span>
          <IconButton onClick={closeSelector} size="large">
            <CloseIcon />
          </IconButton>
        </StyledDialogTitle>
        <DialogContent dividers={scrollType === 'paper'} classes={{root: classes.dialogContent}}>
          <Configure filters={criteria.filters} highlightPreTag="p" highlightPostTag="p" />
          <CriteriaSectionHeaderTitle>
            Include all SKUs that meet the following criteria:
          </CriteriaSectionHeaderTitle>

          {getInclusionCriteria()}
          <CustomCurrentRefinements
            renderLabel={getFacetLabelByFacetName}
            criteria={inclusionFacets}
            inclusive
            onDelete={onDeleteFacetValue}
            readOnly={readOnly}
          />

          <CriteriaSectionHeaderTitle>
            Exclude all SKUs that meet the following criteria:
          </CriteriaSectionHeaderTitle>

          {getExcludeCriteria()}
          <CustomCurrentRefinements
            renderLabel={getFacetLabelByFacetName}
            criteria={exclusionFacets}
            inclusive={false}
            onDelete={onDeleteFacetValue}
            readOnly={readOnly}
          />
          {criteria.resultCount === 0 ? (
            <div ref={noResultRef}>
              <NoResultErrorStats resultLabel="SKU" pluralResultLabel="SKUs" />
            </div>
          ) : null}
        </DialogContent>
        <DialogActions classes={{root: classes.dialogAction}}>
          <CustomStats
            resultLabel="SKU"
            pluralResultLabel="SKUs"
            onResult={onResult}
            canClearCriteria={!readOnly}
            onClearCriteria={onClearCriteria}
            noSelection={noFacetsSelected}
          />
          {!readOnly ? (
            <OptionalTooltip disabled={noFacetsSelected}>
              <ApplyCriteriaButton onClick={applyCriteria} disabled={noFacetsSelected}>
                Apply Criteria
              </ApplyCriteriaButton>
            </OptionalTooltip>
          ) : null}
        </DialogActions>
      </InstantSearch>
      <SelectorCloseConfirmation
        open={closeConfirmModalOpen}
        onCancel={() => setCloseConfirmModalOpen(false)}
        onOk={handleClose}
      />
    </StyledDialog>
  );
};

export default ProductSelector;
